// import type RequestAdapterInterface from '../../interfaces/RequestAdapterInterface';
import StateManager from '../../util/StateManager';
// import type { IPriceCatalogItem } from '../../index';
import type { IPageInfoRequest, IPageInfoResponse, IPageInfo } from '../../index';
import type CatalogData from '~/interfaces/api/catalogData';
import { API_MODX_URL } from '~/EastclinicVueApi/config';
import { postToServer } from '~/EastclinicVueApi/util/UseFetchToServer';

class PageInfoService {
  public async fetch(request: IPageInfoRequest): Promise<IPageInfoResponse> {
    try {
      return await postToServer(API_MODX_URL, request) as IPageInfoResponse;
    } catch (error) {
      console.log('error');
      // Handle the error if needed

      // You might want to return something in case of an error, or throw it again
      // throw error;
    }
  }

  // service function for get catalog of page
  public getCatalogData(pageInfo:IPageInfo) {
    // const pageInfo = this.getPageInfo;

    const parents = [];

    /* eslint-disable brace-style */
    if (pageInfo.isCatalog) {
      parents.push(pageInfo.id);
    }
    else if (pageInfo.id === 6766 || pageInfo.id === 6 || (pageInfo.parents && pageInfo.parents[6766])) {
      parents.push(6766);
    }
    else if (pageInfo.id === 3703 || (pageInfo.parents && pageInfo.parents[3703])) {
      parents.push(3703);
    }
    else if (pageInfo.id === 4 || (pageInfo.parents && pageInfo.parents[4])) {
      parents.push(4);
    }
    /* eslint-enable brace-style */

    interface Data {
      resourceId: number
      alphabet: boolean,
      parents: number[] | null,
      structure?: string
    }

    const data: Data = {
      resourceId: pageInfo.id,
      alphabet: true,
      parents: (parents.length > 0) ? parents : null
    };

    if (!data?.parents) {
      data.parents = [6766, 3703, 4];
    }
    if (!data?.structure) {
      data.structure = 'alphabet';
    }

    const requestData = {
      component: 'east',
      action: 'getResourceCatalog',
      ...data
    };

    return postToServer(API_MODX_URL, requestData) as Promise<CatalogData>;
  }
}
// const pageInfoService = new PageInfoService()
export default new PageInfoService();
