<script setup lang="ts">
  const allowCookies = useCookie('allowCookies', {
    maxAge: 400 * 24 * 60 * 60, // 400 days is Google Chrome limitation
    watch: 'shallow',
    default: () => false
  });
</script>

<template>
  <div v-if="!allowCookies" class="cookie-banner-container">
    <div class="cookie-text-container">
      Мы используем cookie.<br>
      С ними сайт работает стабильнее и быстрее.
      <NuxtLink to="/cookie-policy" class="cookie-link">
        Подробнее
      </NuxtLink>
    </div>
    <button class="cookie-button" @click="allowCookies = true">
      Хорошо
    </button>
  </div>
</template>

<style scoped lang="scss">
.cookie-banner-container {
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: center;
  gap: 16px;
  bottom: 24px;
  left: 50%;
  transform: translate(-50%, 0);
  min-width: 518px;
  background-color: white;
  padding: 20px;
  border-radius: 26px;
  border: 1px solid #E4E7EF;
  box-shadow: 0px 6px 34px 0px rgba(0, 0, 0, 0.10);
  @media (max-width: 767px) {
    flex-direction: column;
    min-width: 335px;
  }
}
.cookie-text-container {
  color: #262633;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  @media (min-width: 767px) {
    text-wrap: nowrap;
  }
}
.cookie-link {
  text-decoration-line: underline;
}
.cookie-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 44px;
  padding: 14px 16px;
  border-radius: 15px;
  background-color: #2D7FF9;
  color: white;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  cursor: pointer;
  @media (max-width: 767px) {
    width: 100%;
  }
}
</style>
