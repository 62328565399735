import type { IClinicsGetListRequest } from '../index';
import { API_MODX_URL } from '../config';
import { postToServer } from '../util/UseFetchToServer';

export default class ClinicsService {
  public async fetchClinics(request:IClinicsGetListRequest) {
    request.component = 'east_filial';
    request.action = 'filials/get';
    try {
      const res = await postToServer(API_MODX_URL, { ...request });
      return res as any;
    } catch (error) {
      console.log('error');
      // Handle the error if needed
      throw error;
    }
  }

}
