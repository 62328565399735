import revive_payload_client_G7QW7Xvc2Q from "/temp/build/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.0_ioredis@5.4.1_magicast@0_kijnocnmewx2aj4xxfi4lmprva/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Q2OkltXSFv from "/temp/build/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.0_ioredis@5.4.1_magicast@0_kijnocnmewx2aj4xxfi4lmprva/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_s33s1nnUS0 from "/temp/build/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.0_ioredis@5.4.1_magicast@0_kijnocnmewx2aj4xxfi4lmprva/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_QIkjfy2ZJI from "/temp/build/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.26.0_vite@5.4.11_@types+node@22.9.0_sass@1.81_etvswubtvfetvq3svchffbwhg4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_JzgWWPXqfq from "/temp/build/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.0_ioredis@5.4.1_magicast@0_kijnocnmewx2aj4xxfi4lmprva/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_5Snvb1YyNz from "/temp/build/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.0_ioredis@5.4.1_magicast@0_kijnocnmewx2aj4xxfi4lmprva/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Er0PhpA8sV from "/temp/build/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.0_ioredis@5.4.1_magicast@0_kijnocnmewx2aj4xxfi4lmprva/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_cEMg7TOX5h from "/temp/build/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.0_ioredis@5.4.1_magicast@0_kijnocnmewx2aj4xxfi4lmprva/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_pfZLgywtdQ from "/temp/build/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.26.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/temp/build/.nuxt/components.plugin.mjs";
import prefetch_client_LMXUJHW1ZX from "/temp/build/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.0_ioredis@5.4.1_magicast@0_kijnocnmewx2aj4xxfi4lmprva/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_jfbzkQtlcj from "/temp/build/node_modules/.pnpm/@vueuse+motion@2.2.6_magicast@0.3.5_rollup@4.26.0_vue@3.5.12_typescript@5.6.3_/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import nuxt_plugin_wvTWgHVXIt from "/temp/build/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.26.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import nuxt3_plugin_V7UZLYaThy from "/temp/build/node_modules/.pnpm/vue-yandex-maps@2.1.4_@vue+runtime-core@3.5.12_nuxt@3.14.159_@parcel+watcher@2.5.0_@types+nod_zpsdkujwft6zpcfsyxp2xfsexm/node_modules/vue-yandex-maps/dist/plugins/nuxt3-plugin.js";
import vmask_MNloZULcpN from "/temp/build/src/plugins/vmask.ts";
import nuxtServerInit_LTWY2PUaTk from "/temp/build/src/plugins/nuxtServerInit.ts";
import scrollTop_e7v3LLiez9 from "/temp/build/src/plugins/scrollTop.ts";
import sentryInit_client_D9KT86gqsm from "/temp/build/src/plugins/sentryInit.client.ts";
export default [
  revive_payload_client_G7QW7Xvc2Q,
  unhead_Q2OkltXSFv,
  router_s33s1nnUS0,
  _0_siteConfig_QIkjfy2ZJI,
  payload_client_JzgWWPXqfq,
  navigation_repaint_client_5Snvb1YyNz,
  check_outdated_build_client_Er0PhpA8sV,
  chunk_reload_client_cEMg7TOX5h,
  plugin_vue3_pfZLgywtdQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_LMXUJHW1ZX,
  motion_jfbzkQtlcj,
  nuxt_plugin_wvTWgHVXIt,
  nuxt3_plugin_V7UZLYaThy,
  vmask_MNloZULcpN,
  nuxtServerInit_LTWY2PUaTk,
  scrollTop_e7v3LLiez9,
  sentryInit_client_D9KT86gqsm
]