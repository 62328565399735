import type SearchResultInterface from '../../interfaces/SearchResultInterface';

import { postToServer, getToServer } from '../../util/UseFetchToServer';
import { API_MODX_URL, BASE_URL } from '../../config';

type TSearchResponse = {
    data:SearchResultInterface[]
};

class SearchService {
  public async searchFetch(searchString:string = '', sessionId:string = '') {
    if (!searchString) {
      const { data } = await postToServer(API_MODX_URL, { component: 'health', action: 'search', searchString, sessionId }) as TSearchResponse;
      return data;
    } else {
      const response = await getToServer(BASE_URL + '/system/api/v1/search', { q: searchString }) as TSearchResponse;
      // const response = await getToServer('http://localhost:8000/api/v1/search', { q: searchString }) as TSearchResponse;
      return response;
    }
  }
}

export default new SearchService();
