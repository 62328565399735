// plugins/vue-mask-plugin.js
import { VueMaskDirective } from 'v-mask';
const vMaskV2 = VueMaskDirective;
const vMaskV3 = {
  beforeMount: vMaskV2.bind,
  updated: vMaskV2.componentUpdated,
  unmounted: vMaskV2.unbind,
  placeholders: {
    // doesn`t work
    Я: /[\wа-яА-Яa-zA-Z]/
  }
};
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('mask', vMaskV3);
});
