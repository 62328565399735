// import * as Sentry from '@sentry/vue';
// export default defineNuxtPlugin((nuxtApp) => {  
//   if (process.env.NODE_ENV !== 'development') {
//     Sentry.init({
//       app: nuxtApp.vueApp,
//       dsn: `https://${useRuntimeConfig().public.SENTRY}.ingest.us.sentry.io/4507865890160640`,
//       // dsn: 'https://ed8c780e3487fab7d7cfabfb800a8b94@o1133417.ingest.us.sentry.io/4507922674483200',
//       integrations: [
//         Sentry.browserTracingIntegration(),
//         Sentry.replayIntegration()
//       ],
//       // Tracing
//       tracesSampleRate: 1.0, //  Capture 100% of the transactions
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: [/^https:\/\/eastclinic\.ru/], // 'localhost:3000',
//       // Session Replay
//       replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//       replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//     });
//   }
// });

export default defineNuxtPlugin(() => {
  //
});
