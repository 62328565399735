export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('page:finish', () => {
    // Чтобы избавиться от scrollTop при первой загрузке (перезагрузке) страницы, ломает работу якорной навигации, которая зависит от скролла
    // const { isHydrating } = useNuxtApp();
    //  else if (isHydrating === false)
    if (history.state.scroll) {
      setTimeout(() => window.scrollTo(history.state.scroll), 100);
    } else {
      setTimeout(() => window.scrollTo(0, 0), 100);
    }
  });
});
