declare global {
  interface Window {
    ym: (id: number, method: string, value: string) => void
  }
}

export const useYandexMetrika = () => {
  return useScript('/ym.js', {
    trigger: new Promise((resolve) => setTimeout(resolve, 3000)),
    key: 'ym',
    use() {
      return {
        recordUrl(url: string) {
          window.ym(19981279, 'hit', url);
        },
        reachGoal(goal: string) {
          window.ym(19981279, 'reachGoal', goal);
        }
      };
    }
  });
};
