import { PlaceLocationsService, SearchService } from '../EastclinicVueApi';
import { useClinicsStore } from '~/stores/ClinicsStore';

export default defineNuxtPlugin(async (nuxtApp) => {
  // Убрано, потому что падает с ошибкой маркдаун, но реальной проблемы обнаружено не было
  // Ошибка: Cannot read properties of null (reading 'insertBefore')
  // При билде пока что такой ошибки не было обнаружено

  // nuxtApp.vueApp.config.errorHandler = (error, _instance, info) => {
  //   if (error) {
  //     showError({
  //       statusCode: 500,
  //       message: info
  //     });
  //   }
  // };
  await useClinicsStore().fetchClinics();
  await PlaceLocationsService.getPlacesFromServer();
  await SearchService.searchFetch();

  const config = useRuntimeConfig();

  const host = useRequestURL().host;
  if (host.includes('site')) {
    config.public.context = 'operator';
  } else if (host.includes('test') || import.meta.dev) {
    config.public.context = 'dev';
  } else {
    config.public.context = 'prod';
  }
});
